import Buraydah from "../../pages/main/contents/buraydah/Buraydah";
import MasterPlan from "../../pages/main/contents/master-plan/MasterPlan";
import Zone1 from "../../pages/main/contents/zone-1/Zone1";
import Zone2 from "../../pages/main/contents/zone-2/Zone2";
import Zone3 from "../../pages/main/contents/zone-3/Zone3";
import Zone4 from "../../pages/main/contents/zone-4/Zone4";
import Zone5 from "../../pages/main/contents/zone-5/Zone5";

export const buttons = [
    {
        title: "menu_buraydah",
        elements: [
            {
                content: <Buraydah />,
            },
        ],
    },
    {
        title: "menu_master_plan",
        elements: [
            {
                content: <MasterPlan />,
            },
        ],
    },
    {
        title: "menu_zones",
        elements: [
            {
                label: "menu_zone_1",
                content: <Zone1 />,
            },
            {
                label: "menu_zone_2",
                content: <Zone2 />,
            },
            {
                label: "menu_zone_3",
                content: <Zone3 />,
                disabled: true,
            },
            {
                label: "menu_zone_4",
                content: <Zone4 />,
                disabled: true,
            },
            {
                label: "menu_zone_5",
                content: <Zone5 />,
            },
        ],
    },
];
