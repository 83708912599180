import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import unitSizeJSON from "./unit_sizes.json";

import { ReactComponent as Block_1_38 } from "./blocks/1/land_plot_block_38.svg";
import { ReactComponent as Block_1_39 } from "./blocks/1/land_plot_block_39.svg";
import { ReactComponent as Block_1_40 } from "./blocks/1/land_plot_block_40.svg";
import { ReactComponent as Block_1_41 } from "./blocks/1/land_plot_block_41.svg";
import { ReactComponent as Block_1_42 } from "./blocks/1/land_plot_block_42.svg";
import { ReactComponent as Block_1_43 } from "./blocks/1/land_plot_block_43.svg";
import { ReactComponent as Block_1_44 } from "./blocks/1/land_plot_block_44.svg";
import { ReactComponent as Block_1_45 } from "./blocks/1/land_plot_block_45.svg";
import { ReactComponent as Block_1_46 } from "./blocks/1/land_plot_block_46.svg";
import { ReactComponent as Block_1_47 } from "./blocks/1/land_plot_block_47.svg";
import { ReactComponent as Block_1_48 } from "./blocks/1/land_plot_block_48.svg";
import { ReactComponent as Block_1_49 } from "./blocks/1/land_plot_block_49.svg";
import { ReactComponent as Block_1_50 } from "./blocks/1/land_plot_block_50.svg";
import { ReactComponent as Block_1_51 } from "./blocks/1/land_plot_block_51.svg";
import { ReactComponent as Block_1_52 } from "./blocks/1/land_plot_block_52.svg";
import { ReactComponent as Block_1_53 } from "./blocks/1/land_plot_block_53.svg";
import { ReactComponent as Block_1_54 } from "./blocks/1/land_plot_block_54.svg";
import { ReactComponent as Block_1_55 } from "./blocks/1/land_plot_block_55.svg";
import { ReactComponent as Block_1_56 } from "./blocks/1/land_plot_block_56.svg";
import { ReactComponent as Block_1_57 } from "./blocks/1/land_plot_block_57.svg";
import { ReactComponent as Block_1_58 } from "./blocks/1/land_plot_block_58.svg";
import { ReactComponent as Block_1_59 } from "./blocks/1/land_plot_block_59.svg";
import { ReactComponent as Block_1_60 } from "./blocks/1/land_plot_block_60.svg";
import { ReactComponent as Block_1_61 } from "./blocks/1/land_plot_block_61.svg";
import { ReactComponent as Block_1_62 } from "./blocks/1/land_plot_block_62.svg";
import { ReactComponent as Block_1_63 } from "./blocks/1/land_plot_block_63.svg";

import { ReactComponent as Block_30_38 } from "./blocks/30/land_plot_block_38.svg";
import { ReactComponent as Block_30_39 } from "./blocks/30/land_plot_block_39.svg";
import { ReactComponent as Block_30_40 } from "./blocks/30/land_plot_block_40.svg";
import { ReactComponent as Block_30_41 } from "./blocks/30/land_plot_block_41.svg";
import { ReactComponent as Block_30_42 } from "./blocks/30/land_plot_block_42.svg";
import { ReactComponent as Block_30_43 } from "./blocks/30/land_plot_block_43.svg";
import { ReactComponent as Block_30_44 } from "./blocks/30/land_plot_block_44.svg";
import { ReactComponent as Block_30_45 } from "./blocks/30/land_plot_block_45.svg";
import { ReactComponent as Block_30_46 } from "./blocks/30/land_plot_block_46.svg";
import { ReactComponent as Block_30_47 } from "./blocks/30/land_plot_block_47.svg";
import { ReactComponent as Block_30_48 } from "./blocks/30/land_plot_block_48.svg";
import { ReactComponent as Block_30_49 } from "./blocks/30/land_plot_block_49.svg";
import { ReactComponent as Block_30_50 } from "./blocks/30/land_plot_block_50.svg";
import { ReactComponent as Block_30_51 } from "./blocks/30/land_plot_block_51.svg";
import { ReactComponent as Block_30_52 } from "./blocks/30/land_plot_block_52.svg";
import { ReactComponent as Block_30_53 } from "./blocks/30/land_plot_block_53.svg";
import { ReactComponent as Block_30_54 } from "./blocks/30/land_plot_block_54.svg";
import { ReactComponent as Block_30_55 } from "./blocks/30/land_plot_block_55.svg";
import { ReactComponent as Block_30_56 } from "./blocks/30/land_plot_block_56.svg";
import { ReactComponent as Block_30_57 } from "./blocks/30/land_plot_block_57.svg";
import { ReactComponent as Block_30_58 } from "./blocks/30/land_plot_block_58.svg";
import { ReactComponent as Block_30_59 } from "./blocks/30/land_plot_block_59.svg";
import { ReactComponent as Block_30_60 } from "./blocks/30/land_plot_block_60.svg";
import { ReactComponent as Block_30_61 } from "./blocks/30/land_plot_block_61.svg";
import { ReactComponent as Block_30_62 } from "./blocks/30/land_plot_block_62.svg";
import { ReactComponent as Block_30_63 } from "./blocks/30/land_plot_block_63.svg";

import { ReactComponent as Block_60_38 } from "./blocks/60/land_plot_block_38.svg";
import { ReactComponent as Block_60_39 } from "./blocks/60/land_plot_block_39.svg";
import { ReactComponent as Block_60_40 } from "./blocks/60/land_plot_block_40.svg";
import { ReactComponent as Block_60_41 } from "./blocks/60/land_plot_block_41.svg";
import { ReactComponent as Block_60_42 } from "./blocks/60/land_plot_block_42.svg";
import { ReactComponent as Block_60_43 } from "./blocks/60/land_plot_block_43.svg";
import { ReactComponent as Block_60_44 } from "./blocks/60/land_plot_block_44.svg";
import { ReactComponent as Block_60_45 } from "./blocks/60/land_plot_block_45.svg";
import { ReactComponent as Block_60_46 } from "./blocks/60/land_plot_block_46.svg";
import { ReactComponent as Block_60_47 } from "./blocks/60/land_plot_block_47.svg";
import { ReactComponent as Block_60_48 } from "./blocks/60/land_plot_block_48.svg";
import { ReactComponent as Block_60_49 } from "./blocks/60/land_plot_block_49.svg";
import { ReactComponent as Block_60_50 } from "./blocks/60/land_plot_block_50.svg";
import { ReactComponent as Block_60_51 } from "./blocks/60/land_plot_block_51.svg";
import { ReactComponent as Block_60_52 } from "./blocks/60/land_plot_block_52.svg";
import { ReactComponent as Block_60_53 } from "./blocks/60/land_plot_block_53.svg";
import { ReactComponent as Block_60_54 } from "./blocks/60/land_plot_block_54.svg";
import { ReactComponent as Block_60_55 } from "./blocks/60/land_plot_block_55.svg";
import { ReactComponent as Block_60_56 } from "./blocks/60/land_plot_block_56.svg";
import { ReactComponent as Block_60_57 } from "./blocks/60/land_plot_block_57.svg";
import { ReactComponent as Block_60_58 } from "./blocks/60/land_plot_block_58.svg";
import { ReactComponent as Block_60_59 } from "./blocks/60/land_plot_block_59.svg";
import { ReactComponent as Block_60_60 } from "./blocks/60/land_plot_block_60.svg";
import { ReactComponent as Block_60_61 } from "./blocks/60/land_plot_block_61.svg";
import { ReactComponent as Block_60_62 } from "./blocks/60/land_plot_block_62.svg";
import { ReactComponent as Block_60_63 } from "./blocks/60/land_plot_block_63.svg";

import { ReactComponent as Block_90_38 } from "./blocks/90/land_plot_block_38.svg";
import { ReactComponent as Block_90_39 } from "./blocks/90/land_plot_block_39.svg";
import { ReactComponent as Block_90_40 } from "./blocks/90/land_plot_block_40.svg";
import { ReactComponent as Block_90_41 } from "./blocks/90/land_plot_block_41.svg";
import { ReactComponent as Block_90_42 } from "./blocks/90/land_plot_block_42.svg";
import { ReactComponent as Block_90_43 } from "./blocks/90/land_plot_block_43.svg";
import { ReactComponent as Block_90_44 } from "./blocks/90/land_plot_block_44.svg";
import { ReactComponent as Block_90_45 } from "./blocks/90/land_plot_block_45.svg";
import { ReactComponent as Block_90_46 } from "./blocks/90/land_plot_block_46.svg";
import { ReactComponent as Block_90_47 } from "./blocks/90/land_plot_block_47.svg";
import { ReactComponent as Block_90_48 } from "./blocks/90/land_plot_block_48.svg";
import { ReactComponent as Block_90_49 } from "./blocks/90/land_plot_block_49.svg";
import { ReactComponent as Block_90_50 } from "./blocks/90/land_plot_block_50.svg";
import { ReactComponent as Block_90_51 } from "./blocks/90/land_plot_block_51.svg";
import { ReactComponent as Block_90_52 } from "./blocks/90/land_plot_block_52.svg";
import { ReactComponent as Block_90_53 } from "./blocks/90/land_plot_block_53.svg";
import { ReactComponent as Block_90_54 } from "./blocks/90/land_plot_block_54.svg";
import { ReactComponent as Block_90_55 } from "./blocks/90/land_plot_block_55.svg";
import { ReactComponent as Block_90_56 } from "./blocks/90/land_plot_block_56.svg";
import { ReactComponent as Block_90_57 } from "./blocks/90/land_plot_block_57.svg";
import { ReactComponent as Block_90_58 } from "./blocks/90/land_plot_block_58.svg";
import { ReactComponent as Block_90_59 } from "./blocks/90/land_plot_block_59.svg";
import { ReactComponent as Block_90_60 } from "./blocks/90/land_plot_block_60.svg";
import { ReactComponent as Block_90_61 } from "./blocks/90/land_plot_block_61.svg";
import { ReactComponent as Block_90_62 } from "./blocks/90/land_plot_block_62.svg";
import { ReactComponent as Block_90_63 } from "./blocks/90/land_plot_block_63.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-1/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-1/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-1/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-1/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-1/icons/2/church_5.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-1/icons/2/hospital_1.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-1/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-1/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-1/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-1/icons/2/school_4.svg";
import { ReactComponent as School_2_5 } from "../../../../static/images/contents/zone-1/icons/2/school_5.svg";
import { ReactComponent as School_2_6 } from "../../../../static/images/contents/zone-1/icons/2/school_6.svg";
import { ReactComponent as School_2_7 } from "../../../../static/images/contents/zone-1/icons/2/school_7.svg";
import { ReactComponent as Museum_2_1 } from "../../../../static/images/contents/zone-1/icons/2/museum_1.svg";
import { ReactComponent as Museum_2_2 } from "../../../../static/images/contents/zone-1/icons/2/museum_2.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-1/icons/2/shopping_1.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-1/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-1/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-1/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-1/icons/33/church_4.svg";
import { ReactComponent as Church_33_5 } from "../../../../static/images/contents/zone-1/icons/33/church_5.svg";
import { ReactComponent as Church_33_6 } from "../../../../static/images/contents/zone-1/icons/33/church_6.svg";
import { ReactComponent as Church_33_7 } from "../../../../static/images/contents/zone-1/icons/33/church_7.svg";
import { ReactComponent as Church_33_8 } from "../../../../static/images/contents/zone-1/icons/33/church_8.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-1/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-1/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-1/icons/33/school_3.svg";
import { ReactComponent as School_33_4 } from "../../../../static/images/contents/zone-1/icons/33/school_4.svg";
import { ReactComponent as School_33_5 } from "../../../../static/images/contents/zone-1/icons/33/school_5.svg";
import { ReactComponent as School_33_6 } from "../../../../static/images/contents/zone-1/icons/33/school_6.svg";
import { ReactComponent as School_33_7 } from "../../../../static/images/contents/zone-1/icons/33/school_7.svg";
import { ReactComponent as Museum_33_2 } from "../../../../static/images/contents/zone-1/icons/33/museum_2.svg";
import { ReactComponent as Shopping_33_1 } from "../../../../static/images/contents/zone-1/icons/33/shopping_1.svg";

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-1/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-1/icons/61/church_2.svg";
import { ReactComponent as Church_61_3 } from "../../../../static/images/contents/zone-1/icons/61/church_3.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-1/icons/61/church_4.svg";
import { ReactComponent as Church_61_5 } from "../../../../static/images/contents/zone-1/icons/61/church_5.svg";
import { ReactComponent as Church_61_6 } from "../../../../static/images/contents/zone-1/icons/61/church_6.svg";
import { ReactComponent as Church_61_7 } from "../../../../static/images/contents/zone-1/icons/61/church_7.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-1/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-1/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-1/icons/61/school_3.svg";
import { ReactComponent as School_61_4 } from "../../../../static/images/contents/zone-1/icons/61/school_4.svg";
import { ReactComponent as School_61_5 } from "../../../../static/images/contents/zone-1/icons/61/school_5.svg";
import { ReactComponent as School_61_6 } from "../../../../static/images/contents/zone-1/icons/61/school_6.svg";
import { ReactComponent as School_61_7 } from "../../../../static/images/contents/zone-1/icons/61/school_7.svg";
import { ReactComponent as School_61_8 } from "../../../../static/images/contents/zone-1/icons/61/school_8.svg";
import { ReactComponent as Museum_61_2 } from "../../../../static/images/contents/zone-1/icons/61/museum_2.svg";
import { ReactComponent as Shopping_61_1 } from "../../../../static/images/contents/zone-1/icons/61/shopping_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-1/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-1/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-1/icons/91/church_3.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-1/icons/91/church_4.svg";
import { ReactComponent as Church_91_5 } from "../../../../static/images/contents/zone-1/icons/91/church_5.svg";
import { ReactComponent as Church_91_6 } from "../../../../static/images/contents/zone-1/icons/91/church_6.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-1/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-1/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-1/icons/91/school_3.svg";
import { ReactComponent as School_91_4 } from "../../../../static/images/contents/zone-1/icons/91/school_4.svg";
import { ReactComponent as School_91_5 } from "../../../../static/images/contents/zone-1/icons/91/school_5.svg";
import { ReactComponent as Museum_91_2 } from "../../../../static/images/contents/zone-1/icons/91/museum_2.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-1/icons/91/shopping_1.svg";


export const zone1_blocks = [
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                            {
                                //3
                                Polygon: Block_1_38,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 38,
                                    status: "sold",
                                },
                            },
                            {
                                //3
                                Polygon: Block_1_39,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 39,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_40,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 40,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_41,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 41,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_42,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 42,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_43,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 43,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_44,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 44,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_45,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 45,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_46,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 46,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_47,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 47,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_48,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 48,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_49,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 49,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_50,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 50,
                                    status: "sold",
                                },
                            },
                            {
                                //3
                                Polygon: Block_1_51,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 51,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_52,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 52,
                                    status: "available",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_53,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 53,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_54,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 54,
                                    status: "sold",
                                },
                            },
                            {
                                //3
                                Polygon: Block_1_55,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 55,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_56,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 56,
                                    status: "booked",
                                },
                            },
                            {
                                //3
                                Polygon: Block_1_57,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 57,
                                    status: "booked",
                                },
                            },
                            {
                                //3
                                Polygon: Block_1_58,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 58,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_59,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 59,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_60,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 60,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_61,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 61,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_62,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 62,
                                    status: "sold",
                                },
                                
                            },
                            {
                                //3
                                Polygon: Block_1_63,
                                className: null,
                                infobox: {
                                    type: "block_info",
                                    index: 63,
                                    status: "sold",
                                },
                                
                            },
                        ]}            
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                ]}
                />
            </>
        ),
    },
    {
        index: 31,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3
                            Polygon: Block_30_38,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 38,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_30_39,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 39,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_40,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 40,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_41,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 41,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_42,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 42,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_43,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 43,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_44,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 44,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_45,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 45,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_46,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 46,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_47,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 47,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_48,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 48,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_49,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 49,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_50,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 50,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_30_51,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 51,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_52,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 52,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_53,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 53,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_54,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 54,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_30_55,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 55,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_56,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 56,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_30_57,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 57,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_30_58,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 58,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_59,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 59,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_60,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 60,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_61,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 61,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_62,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 62,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_30_63,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 63,
                                status: "sold",
                            },
                            
                        },
                    ]}            
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3
                            Polygon: Block_60_38,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 38,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_60_39,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 39,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_40,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 40,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_41,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 41,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_42,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 42,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_43,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 43,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_44,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 44,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_45,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 45,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_46,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 46,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_47,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 47,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_48,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 48,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_49,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 49,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_50,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 50,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_60_51,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 51,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_52,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 52,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_53,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 53,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_54,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 54,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_60_55,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 55,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_56,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 56,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_60_57,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 57,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_60_58,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 58,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_59,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 59,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_60,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 60,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_61,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 61,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_62,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 62,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_60_63,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 63,
                                status: "sold",
                            },
                            
                        },
                    ]}            
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            //3
                            Polygon: Block_90_38,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 38,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_90_39,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 39,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_40,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 40,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_41,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 41,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_42,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 42,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_43,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 43,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_44,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 44,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_45,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 45,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_46,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 46,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_47,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 47,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_48,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 48,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_49,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 49,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_50,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 50,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_90_51,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 51,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_52,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 52,
                                status: "available",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_53,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 53,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_54,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 54,
                                status: "sold",
                            },
                        },
                        {
                            //3
                            Polygon: Block_90_55,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 55,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_56,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 56,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_90_57,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 57,
                                status: "booked",
                            },
                        },
                        {
                            //3
                            Polygon: Block_90_58,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 58,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_59,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 59,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_60,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 60,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_61,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 61,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_62,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 62,
                                status: "sold",
                            },
                            
                        },
                        {
                            //3
                            Polygon: Block_90_63,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 63,
                                status: "sold",
                            },
                            
                        },
                    ]}            
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Museum_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    
                    ]}
                />
            </>
        ),
    },
];
