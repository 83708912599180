export const button_area_animation = (isActive, disabled = false) => ({
    animate: {
        backgroundColor: isActive
            ? "rgba(242, 220, 206, 1)"
            : !disabled
            ? "rgba(204, 125, 97, 1)"
            : "rgba(88, 88, 88, 0.36)",
        color: isActive ? "rgba(48, 74, 56, 1)" : "rgba(255, 255, 255, 1)",
    },
    whileHover: {
        backgroundColor: isActive
            ? "rgba(242, 220, 206, 1)"
            : !disabled
            ? "rgba(218, 144, 118, 1)"
            : null,
    },
    style: {
        cursor: isActive || !disabled ? "pointer" : "auto",
    },
    transition: { duration: 0.15 },
});

export const button_area_arrow_animation = (isExpanded, isMobile) => ({
    animate: { rotate: isExpanded ? (!isMobile ? 180 : 180) : 0 },
    transition: { duration: 0.2 },
});

export const button_elements_animatation = (isMobile) => ({
    initial: {
        opacity: 0,
        transform: !isMobile
            ? "translate(-17px, calc(-100%))"
            : "translate(calc(0px), calc(-35px - 100%)",
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: { duration: 0.3 },
});
